import React, { useEffect, useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import axios from "axios";

import { userConfirmReplaceURL, userDeclineReplaceURL } from "../lib/constants";
import { Furlough } from "../pages/Admin";
import { vazir, vazirBlack, vazirMedium } from "./Theme";

interface furloughRequestProps {
  role: "user" | "admin";
  furlough: Furlough;
}

const useStyles = makeStyles(() => ({
  confirmBtn: {
    fontFamily: vazirMedium,
    fontSize: 14,
    marginLeft: 10,
    alignSelf: "center",
  },
  text: {
    fontFamily: vazir,
    color: "black",
    textAlign: "right",
    lineHeight: 3,
  },
  variableText: {
    fontFamily: vazirBlack,
    color: "black",
    lineHeight: 3,
  },
}));

const FurloughRequest = ({
  role,
  furlough: propFurlough,
}: furloughRequestProps) => {
  const classes = useStyles();

  const [furlough, setFurlough] = useState<any>(null);

  const confirmReplace = () => {
    axios
      .post(userConfirmReplaceURL, {
        id: furlough.id,
        replaceUserId: furlough.replacedUserId,
      })
      .then((res) => {
        if (res.data.status === "success") {
          alert("درخواست با موفقیت تایید شد");
          setFurlough(null);
        } else {
          alert("خطایی رخ داده لطفاً دوباره تلاشی نمایید");
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const declineReplace = () => {
    axios
      .post(userDeclineReplaceURL, {
        id: furlough.replacedUserId,
      })
      .then((res) => {
        if (res.data.status === "success") {
          alert("درخواست با موفقیت لغو شد.");
          setFurlough(null);
        } else {
          alert("خطایی رخ داده لطفاً دوباره تلاشی نمایید");
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  useEffect(() => {
    setFurlough(propFurlough);
  }, []);

  return (
    <div>
      {role === "user" ? (
        <div>
          <span className={classes.text}>
            جناب آقای/سرکار خانم{" "}
            <span className={classes.variableText}>
              {furlough.replaceUserName}{" "}
            </span>
            تقاضای{" "}
            <span className={classes.variableText}>{furlough.amount}</span> روز
            مرخصی از تاریخ{" "}
            <span className={classes.variableText}>{furlough.startDate}</span>{" "}
            الی <span className={classes.variableText}>{furlough.endDate}</span>{" "}
            داشته و شما را به عنوان جایگزین انتخاب کرده است.{" "}
          </span>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#44bd32",
              color: "white",
              paddingTop: 5,
              paddingBottom: 5,
            }}
            className={classes.confirmBtn}
            onClick={confirmReplace}
          >
            تایید
          </Button>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            style={{
              backgroundColor: "#e84118",
              color: "white",
              paddingTop: 5,
              paddingBottom: 5,
            }}
            className={classes.confirmBtn}
            onClick={declineReplace}
          >
            لغو
          </Button>
        </div>
      ) : (
        <div>
          <span className={classes.text}>
            جناب آقای/سرکار خانم{" "}
            <span className={classes.variableText}>
              {furlough.mainUserName}{" "}
            </span>
            تقاضای{" "}
            <span className={classes.variableText}>{furlough.amount}</span> روز
            مرخصی از تاریخ{" "}
            <span className={classes.variableText}>{furlough.startDate}</span>{" "}
            الی <span className={classes.variableText}>{furlough.endDate}</span>{" "}
            داشته و{" "}
            <span className={classes.variableText}>
              {furlough.replaceUserName}{" "}
            </span>{" "}
            را به عنوان جایگزین انتخاب کرده است.{" "}
          </span>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#44bd32",
              color: "white",
              paddingTop: 5,
              paddingBottom: 5,
            }}
            className={classes.confirmBtn}
            onClick={confirmReplace}
          >
            تایید
          </Button>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            style={{
              backgroundColor: "#e84118",
              color: "white",
              paddingTop: 5,
              paddingBottom: 5,
            }}
            className={classes.confirmBtn}
            onClick={declineReplace}
          >
            لغو
          </Button>
        </div>
      )}
    </div>
  );
};

export default FurloughRequest;
