import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { SignIn, Admin, Home } from "./pages";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="/admin" exact component={Admin} />
        <Route path="/user" exact component={Home} />
      </Switch>
    </Router>
  );
}

export default App;
