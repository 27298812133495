import React, { useEffect, useState } from "react";
import axios from "axios";
import AddIcon from "@material-ui/icons/Add";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import {
  adminConfirmReplaceURL,
  authenticateUserURL,
  createNewUserURL,
  getFurloughURL,
  getRequestsIdURL,
  getUserNameURL,
  userDeclineReplaceURL,
} from "../lib/constants";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { vazir, vazirBlack, vazirBold, vazirMedium } from "../components";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    marginTop: 25,
    padding: 25,
    direction: "rtl",
  },
  text: {
    fontFamily: vazir,
    color: "black",
    textAlign: "right",
    lineHeight: 3,
  },
  variableText: {
    fontFamily: vazirBlack,
    // color: "#3498db",
    color: "black",
    lineHeight: 3,
  },
  confirmBtn: {
    fontFamily: vazirMedium,
    fontSize: 14,
    marginLeft: 10,
    alignSelf: "center",
  },
  headerText: {
    fontFamily: vazirBold,
    fontSize: 25,
  },
  dialog: {
    padding: 25,
  },
  dialogTitle: {
    fontFamily: vazirBold,
    fontSize: 20,
    marginBottom: 25,
    direction: "rtl",
  },
  label: {
    fontFamily: vazirMedium,
    color: "black",
    marginBottom: 10,
  },
}));

export type Furlough = {
  id: number;
  mainUserName: string;
  mainUserId: number;
  replaceUserName: string;
  replacedUserId: number;
  startDate: string;
  endDate: string;
  amount: string;
  cause: string;
};

function Admin() {
  const classes = useStyles();
  // const router = useRouter();
  const history = useHistory();

  const [city, setCity] = useState("");
  const [requests, setRequests] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(true);

  //Dialog
  const [open, setOpen] = useState(false);

  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [leaves, setLeaves] = useState("");

  const [hiredDate, setHiredDate] = useState("");

  const handleTextChange = (name: string) => (e: any) => {
    if (name === "username") {
      setUsername(e.target.value);
    } else if (name === "password") {
      setPassword(e.target.value);
    } else if (name === "name") {
      setName(e.target.value);
    } else if (name === "hiredDate") {
      setHiredDate(e.target.value);
    } else {
      setLeaves(e.target.value);
    }
  };

  const createNewUser = () => {
    axios
      .post(createNewUserURL, {
        username,
        password,
        name,
        city,
        hiredDate,
        leaves,
      })
      .then((res) => {
        if (res.data.status === "success") {
          alert("کاربر جدید با موفقیت اضافه شد");
        } else {
          alert("خطایی رخ داده لطفاً دوباره تلاشی نمایید");
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const getUserData = () => {
    const authJson = sessionStorage.getItem("auth");
    if (authJson) {
      const { username, token } = JSON.parse(authJson);

      axios
        .post(authenticateUserURL, {
          username,
          token,
        })
        .then((res) => {
          if (res.data.status === "Verified") {
            setCity(res.data.city);
            getReplaceRequestsId(res.data.city);
          } else {
            history.replace("/");
          }
        })
        .catch((err) => {
          if (err) throw new Error(err);
        });
    } else {
      history.replace("/");
    }
  };

  const getReplaceRequestsId = (cityParam: any) => {
    axios
      .post(getRequestsIdURL, {
        city: cityParam,
      })
      .then((res) => {
        getFurlough(res.data.requestsId);
      })
      .catch((err) => {
        if (err) {
          alert("خطایی رخ داده لطفاً دوباره تلاش کنید");
          console.log(err);
        }
      });
  };

  const getFurlough = (requestsIdParam: any[]) => {
    let counter = 0;

    requestsIdParam.map(({ replaceRequest }) => {
      if (replaceRequest !== "0") {
        counter += replaceRequest.split(",").length;
      }
    });

    if (counter !== 0) {
      const requestArray: any[] = [];
      requestsIdParam.map(({ replaceRequest }) => {
        if (replaceRequest !== 0) {
          const idArr = replaceRequest.split(",");

          idArr.map((id: any) => {
            if (id !== "0") {
              axios
                .post(getFurloughURL, {
                  id,
                })
                .then((res) => {
                  axios
                    .post(getUserNameURL, {
                      id: res.data.furlough.mainUserId,
                    })
                    .then((mun) => {
                      const mainUserName = mun.data;
                      axios
                        .post(getUserNameURL, {
                          id: res.data.furlough.replacedUserId,
                        })
                        .then((run) => {
                          const replaceUserName = run.data;
                          const r: Furlough = {
                            id,
                            amount: res.data.furlough.amount,
                            cause: res.data.furlough.cause,
                            endDate: res.data.furlough.endDate,
                            startDate: res.data.furlough.startDate,
                            mainUserId: res.data.furlough.mainUserId,
                            replacedUserId: res.data.furlough.replacedUserId,
                            mainUserName,
                            replaceUserName,
                          };
                          requestArray.push(r);
                          if (counter === requestArray.length) {
                            setRequests(requestArray);
                            setIsLoading(false);
                          }
                        })
                        .catch((err) => {
                          if (err) {
                            alert("خطایی رخ داده لطفاً دوباره تلاش کنید");
                            console.log(err);
                          }
                        });
                    })
                    .catch((err) => {
                      if (err) {
                        alert("خطایی رخ داده لطفاً دوباره تلاش کنید");
                        console.log(err);
                      }
                    });
                })
                .catch((err) => {
                  if (err) {
                    alert("خطایی رخ داده لطفاً دوباره تلاش کنید");
                    console.log(err);
                  }
                });
            }
          });
        }
      });
    }
    setIsLoading(false);
  };

  const confirmReplace = (furlough: {
    id: any;
    mainUserName?: string;
    mainUserId?: number;
    replaceUserName?: string;
    replacedUserId: any;
    startDate?: string;
    endDate?: string;
    amount?: string;
    cause?: string;
    indexOf?: any;
  }) => {
    axios
      .post(adminConfirmReplaceURL, {
        id: furlough.id,
        replaceUserId: furlough.replacedUserId,
      })
      .then((res) => {
        if (res.data.status === "success") {
          alert("درخواست با موفقیت تایید شد");
          setRequests((prev: any[]) => {
            if (prev.length === 1) {
              return [];
            } else {
              return prev.splice(
                requests.indexOf((e: { id: any }) => e.id === furlough.id),
                1
              );
            }
          });
        } else {
          alert("خطایی رخ داده لطفاً دوباره تلاشی نمایید");
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const declineReplace = (furlough: {
    id: any;
    mainUserName?: string;
    mainUserId?: number;
    replaceUserName?: string;
    replacedUserId: any;
    startDate?: string;
    endDate?: string;
    amount?: string;
    cause?: string;
    indexOf?: any;
  }) => {
    axios
      .post(userDeclineReplaceURL, {
        id: furlough.id,
        replaceUserId: furlough.replacedUserId,
      })
      .then((res) => {
        if (res.data.status === "success") {
          alert("درخواست با موفقیت لغو شد.");
          setRequests((prev: any[]) => {
            if (prev.length === 1) {
              return [];
            } else {
              return prev.splice(
                requests.indexOf((e: { id: any }) => e.id === furlough.id),
                1
              );
            }
          });
        } else {
          alert("خطایی رخ داده لطفاً دوباره تلاشی نمایید");
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  useEffect(() => {
    getUserData();
  }, [isLoading]);

  if (isLoading) {
    return (
      <Backdrop style={{ zIndex: 1000, color: "white" }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    return (
      <Grid container>
        <Grid xs={1} sm={2} item />
        <Grid xs={10} sm={8} item container>
          <Paper className={classes.root}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 25,
              }}
            >
              <Typography className={classes.headerText}>
                درخواست های مرخصی
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
                className={classes.confirmBtn}
                onClick={() => setOpen(true)}
                startIcon={<AddIcon style={{ marginLeft: 15 }} />}
              >
                اضافه کردن کاربر
              </Button>
            </div>
            {requests.length === 0 ? (
              <Typography
                className={classes.headerText}
                style={{ fontSize: 20 }}
              >
                درخواست جدیدی ثبت نشده است.
              </Typography>
            ) : (
              requests.map((req: any, index: number) => {
                return (
                  <div>
                    <span className={classes.text}>
                      <span className={classes.variableText}>{index + 1}</span>)
                      جناب آقای/سرکار خانم{" "}
                      <span className={classes.variableText}>
                        {req.mainUserName}{" "}
                      </span>
                      تقاضای{" "}
                      <span className={classes.variableText}>{req.amount}</span>{" "}
                      روز مرخصی از تاریخ{" "}
                      <span className={classes.variableText}>
                        {req.startDate}
                      </span>{" "}
                      الی{" "}
                      <span className={classes.variableText}>
                        {req.endDate}
                      </span>{" "}
                      داشته و{" "}
                      <span className={classes.variableText}>
                        {req.replaceUserName}{" "}
                      </span>{" "}
                      را به عنوان جایگزین انتخاب کرده است.{" "}
                    </span>
                    <Button
                      variant="contained"
                      size="large"
                      style={{
                        backgroundColor: "#44bd32",
                        color: "white",
                        paddingTop: 5,
                        paddingBottom: 5,
                      }}
                      className={classes.confirmBtn}
                      onClick={() => confirmReplace(req)}
                    >
                      تایید
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      style={{
                        backgroundColor: "#e84118",
                        color: "white",
                        paddingTop: 5,
                        paddingBottom: 5,
                      }}
                      className={classes.confirmBtn}
                      onClick={() => declineReplace(req)}
                    >
                      لغو
                    </Button>
                  </div>
                );
              })
            )}
          </Paper>
        </Grid>
        <Grid xs={1} sm={2} item />
        <Dialog
          className={classes.dialog}
          onClose={() => setOpen(false)}
          open={open}
        >
          <DialogTitle>
            <Typography className={classes.dialogTitle}>
              ایجاد کاربر جدید
            </Typography>
            <Typography align="right" className={classes.label}>
              : نام کاربری
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Username"
              value={username}
              onChange={handleTextChange("username")}
              fullWidth
            />
            <Typography
              align="right"
              className={classes.label}
              style={{ marginTop: 25 }}
            >
              : رمز عبور
            </Typography>
            <FormControl variant="outlined" size="small" fullWidth>
              <OutlinedInput
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handleTextChange("password")}
                placeholder="************"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Typography
              align="right"
              style={{ marginTop: 25 }}
              className={classes.label}
            >
              : نام و نام خانوادگی
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="علی سیف الدینی"
              value={name}
              onChange={handleTextChange("name")}
              fullWidth
              style={{ direction: "rtl", fontFamily: vazir }}
              InputProps={{
                style: {
                  fontFamily: vazir,
                },
              }}
            />
            <Typography
              align="right"
              style={{ marginTop: 25 }}
              className={classes.label}
            >
              : تاریخ استخدام
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="1399/1/1"
              value={hiredDate}
              onChange={handleTextChange("hiredDate")}
              fullWidth
              style={{ direction: "ltr", fontFamily: vazir }}
              InputProps={{
                style: {
                  fontFamily: vazir,
                },
              }}
            />
            <Typography
              align="right"
              style={{ marginTop: 25 }}
              className={classes.label}
            >
              : مرخصی های استفاده شده
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="0"
              type="number"
              value={leaves}
              onChange={handleTextChange("leaves")}
              fullWidth
              style={{ direction: "ltr", fontFamily: vazir }}
              InputProps={{
                style: {
                  fontFamily: vazir,
                },
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 40,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
                className={classes.confirmBtn}
                onClick={createNewUser}
              >
                ایجاد
              </Button>
            </div>
          </DialogTitle>
        </Dialog>
      </Grid>
    );
  }
}

export default Admin;
