import React, { useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { vazir, vazirBold, vazirMedium } from "../components";
import { getTokenURL, signInURL } from "../lib/constants";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: 25,
    marginTop: 25,
  },
  headerText: {
    fontFamily: vazirBold,
    fontSize: 36,
  },
  label: {
    fontFamily: vazirMedium,
    color: "black",
    marginBottom: 5,
  },
  signUpBtn: {
    fontFamily: vazirMedium,
    fontSize: 14,
    marginRight: 10,
  },
  signInBtn: {
    fontFamily: vazirMedium,
    fontSize: 14,
    marginLeft: 10,
  },
  alreadySignedUpBtn: {
    fontFamily: vazir,
    fontSize: 13,
    marginBottom: 10,
  },
  rememberMeTxt: {
    fontFamily: vazirMedium,
    fontSize: 14,
  },
}));

export default function SignIn() {
  const classes = useStyles();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);

  // const router = useRouter();
  const history = useHistory();

  const handleTextChange = (name: string) => (e: any) => {
    if (name === "username") {
      setUsername(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  // const handleSignUpBtn = () => {};

  // const handleForgetPasswordBtn = () => {};

  const handleSignInBtn = () => {
    if (username.length === 0 && password.length === 0) {
      alert("نام کاربری و رمز عبور نمیتواند خالی باشد");
    } else if (username.length === 0) {
      alert("نام کاربری نمیتواند خالی باشد");
    } else if (password.length === 0) {
      alert("رمز عبور نمیتواند خالی باشد");
    } else {
      axios
        .post(signInURL, {
          username,
          password,
        })
        .then((res) => {
          if (res.data.status === "granted") {
            axios
              .post(getTokenURL, {
                username,
                password,
              })
              .then((result) => {
                const auth = {
                  token: result.data.token,
                  name: res.data.name,
                  username,
                };
                sessionStorage.setItem("auth", JSON.stringify(auth));

                if (res.data.role === "user") {
                  // router.push("/home");
                  history.push("/user");
                } else if (res.data.role === "admin") {
                  // router.push("/admin");
                  history.push("/admin");
                }
              })
              .catch((err) => {
                if (err) alert("خطایی رخ داده لطفاً دوباره تلاش کنید");
              });
          } else {
            alert("نام کاربری یا رمز عبور اشتباه است لطفاً دوباره تلاش کنید");
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
  };

  // const handleCheckBoxChange = () => {
  //   setIsRememberMeChecked(!isRememberMeChecked);
  // };

  return (
    <Grid container>
      <Grid xs={1} sm={2} md={3} lg={4} item />
      <Grid xs={10} sm={8} md={6} lg={4} item container>
        <Paper className={classes.root}>
          <Typography
            gutterBottom
            align="center"
            className={classes.headerText}
          >
            ورود
          </Typography>
          <div>
            <Typography align="right" className={classes.label}>
              نام کاربری
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Username"
              onChange={handleTextChange("username")}
              value={username}
              fullWidth
            />
          </div>
          <div style={{ marginTop: 15 }}>
            <Typography align="right" className={classes.label}>
              رمز عبور
            </Typography>
            <FormControl variant="outlined" size="small" fullWidth>
              <OutlinedInput
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handleTextChange("password")}
                placeholder="************"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
              marginTop: 15,
            }}
          >
            <Checkbox
              checked={isRememberMeChecked}
              onChange={handleCheckBoxChange}
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <Typography className={classes.rememberMeTxt}>
              مرا بخاطر بسپار
            </Typography>
          </div> */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 25,
            }}
          >
            {/* <Button
              variant="text"
              size="small"
              color="primary"
              className={classes.alreadySignedUpBtn}
              onClick={handleForgetPasswordBtn}
            >
              رمز عبور را فراموش کرده‌اید ؟
            </Button> */}
            <div>
              {/* <Button
                variant="outlined"
                size="large"
                color="primary"
                className={classes.signUpBtn}
                onClick={handleSignUpBtn}
              >
                ثبت نام
              </Button> */}
              <Button
                variant="contained"
                size="large"
                color="primary"
                className={classes.signInBtn}
                onClick={handleSignInBtn}
              >
                ورود
              </Button>
            </div>
          </div>
        </Paper>
      </Grid>
      <Grid xs={1} sm={2} md={3} lg={4} item />
    </Grid>
  );
}
