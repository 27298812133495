import moment from "moment-jalaali";

const convertDate = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const mDate = `${year}-${month}-${day}`;
  const shDate = moment(mDate, "YYYY-M-D").format("jYYYY/jM/jD");

  return shDate;
};

export default convertDate;
