import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import moment from "moment-jalaali";
import axios from "axios";
import Select, { ValueType } from "react-select";
import DatePicker, { DayValue } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

import { vazir, vazirBlack, vazirBold, vazirMedium } from "../components";
import convertDate from "../lib/convertDate";
import {
  authenticateUserURL,
  getAvailableUsersURL,
  getReplaceRequestsURL,
  getUserNameURL,
  submitFurloughURL,
  userConfirmReplaceURL,
  userDeclineReplaceURL,
} from "../lib/constants";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    marginTop: 25,
    padding: 25,
    direction: "rtl",
  },
  headerText: {
    fontFamily: vazirBold,
    fontSize: 28,
  },
  label: {
    fontFamily: vazirMedium,
    color: "black",
    marginBottom: 5,
  },
  text: {
    fontFamily: vazir,
    color: "black",
    textAlign: "right",
    lineHeight: 3,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    height: 30,
    border: "1px solid #ddd",
    padding: "0.4em 0.8em",
    width: 167.7,
    fontFamily: vazirBold,
    font: "400 13.3333px ",
    "&::placeholder": {
      fontFamily: vazirBold,
      font: "400 13.3333px ",
    },
  },
  variableText: {
    fontFamily: vazirBlack,
    // color: "#3498db",
    color: "black",
    lineHeight: 3,
  },
  secondText: {
    fontFamily: vazir,
    color: "black",
    textAlign: "right",
    lineHeight: 3,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  confirmBtn: {
    fontFamily: vazirMedium,
    fontSize: 14,
    marginLeft: 10,
    alignSelf: "center",
  },
}));

const colourStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "white",
    height: 30,
    width: 167.7,
    fontSize: 16,
    padding: undefined,
    position: undefined,
    minHeight: 30,
  }),
  input: (styles: any) => ({ ...styles, fontFamily: vazirBlack, fontSize: 13 }),
  placeholder: (styles: any) => ({
    ...styles,
    fontFamily: vazirBlack,
    fontSize: 13,
  }),
  singleValue: (styles: any) => ({
    ...styles,
    fontFamily: vazirBlack,
    fontSize: 13,
  }),
  option: (styles: any) => ({
    ...styles,
    fontFamily: vazirBlack,
    fontSize: 13,
    width: 167.7,
  }),
  valueContainer: (_: any) => ({
    height: 30,
    margin: undefined,
    paddingRight: 5,
  }),
  indicatorsContainer: (styles: any) => ({
    ...styles,
    height: 30,
  }),
  container: (styles: any) => ({
    ...styles,
    width: 167.7,
  }),
};

type availableUser = {
  value: string;
  label: string;
};

const Home = () => {
  const classes = useStyles();
  const history = useHistory();

  const [name, setName] = useState("");
  const [todayDate, setTodayDate] = useState("");
  const [amount, setAmount] = useState(1);
  const [cause, setCause] = useState("کار شخصی");
  const [id, setId] = useState("");

  const [startLeaveDate, setStartLeaveDate] = useState<DayValue>(null);
  const [endLeaveDate, setEndLeaveDate] = useState<DayValue>(null);

  const [isDigitalSignChecked, setIsDigitalSignChecked] = useState(false);

  const [availableUsers, setAvailableUsers] = useState<availableUser[]>([]);
  const [availableUser, setAvailableUser] = useState<
    ValueType<availableUser> | null | undefined | any
  >(null);

  const [furlough, setFurlough] = useState<any>(null);

  const handleTextChange = (type: "amount" | "cause") => (e: any) => {
    if (type === "amount") {
      setAmount(e.target.value);
    } else {
      setCause(e.target.value);
    }
  };

  const calculateLeaveDuration = (date: DayValue) => {
    const endDate = moment(`${date?.year}/${date?.month}/${date?.day}`);

    const startDate = moment(
      `${startLeaveDate?.year}/${startLeaveDate?.month}/${startLeaveDate?.day}`
    );

    var diff = endDate.diff(startDate, "days");

    if (diff === 0) diff = 0.5;
    setAmount(diff);
  };

  const getReplaceRequests = () => {};

  const handleDateChange = (date: DayValue, type: "start" | "end") => {
    const [year, month, day] = todayDate.split("/");
    if (date && type === "start") {
      if (endLeaveDate) {
        if (
          date.year === parseInt(year) &&
          date.month === parseInt(month) &&
          date.day < parseInt(day)
        ) {
          alert("تاریخ شروع مرخصی نمیتواند قبل از تاریخ امروز باشد");
          return;
        } else if (
          date.year === parseInt(year) &&
          date.month < parseInt(month)
        ) {
          alert("تاریخ شروع مرخصی نمیتواند قبل از تاریخ امروز باشد");
          return;
        } else if (date.year < parseInt(year)) {
          alert("تاریخ شروع مرخصی نمیتواند قبل از تاریخ امروز باشد");
          return;
        } else if (
          endLeaveDate.year === date.year &&
          endLeaveDate.month === date.month &&
          endLeaveDate.day < date.day
        ) {
          alert("تاریخ شروع مرخصی نمیتواند بعد از تاریخ اتمام مرخصی باشد");
          return;
        } else if (
          endLeaveDate.year === date.year &&
          endLeaveDate.month < date.month
        ) {
          alert("تاریخ شروع مرخصی نمیتواند بعد از تاریخ اتمام مرخصی باشد");
          return;
        } else if (endLeaveDate.year < date.year) {
          alert("تاریخ شروع مرخصی نمیتواند بعد از تاریخ اتمام مرخصی باشد");
          return;
        } else {
          setStartLeaveDate(date);
        }
      }
      if (
        date.year === parseInt(year) &&
        date.month === parseInt(month) &&
        date.day < parseInt(day)
      ) {
        alert("تاریخ شروع مرخصی نمیتواند قبل از تاریخ امروز باشد");
        return;
      } else if (date.year === parseInt(year) && date.month < parseInt(month)) {
        alert("تاریخ شروع مرخصی نمیتواند قبل از تاریخ امروز باشد");
        return;
      } else if (date.year < parseInt(year)) {
        alert("تاریخ شروع مرخصی نمیتواند قبل از تاریخ امروز باشد");
        return;
      } else {
        setStartLeaveDate(date);
      }
    } else if (date && type === "end") {
      if (startLeaveDate) {
        if (
          date.year === parseInt(year) &&
          date.month === parseInt(month) &&
          date.day < parseInt(day)
        ) {
          alert("تاریخ اتمام مرخصی نمیتواند قبل از تاریخ امروز باشد");
          return;
        } else if (
          date.year === parseInt(year) &&
          date.month < parseInt(month)
        ) {
          alert("تاریخ اتمام مرخصی نمیتواند قبل از تاریخ امروز باشد");
          return;
        } else if (date.year < parseInt(year)) {
          alert("تاریخ اتمام مرخصی نمیتواند قبل از تاریخ امروز باشد");
          return;
        } else if (
          startLeaveDate.year === date.year &&
          startLeaveDate.month === date.month &&
          startLeaveDate.day > date.day
        ) {
          alert("تاریخ اتمام مرخصی نمیتواند قبل از تاریخ شروع مرخصی باشد");
          return;
        } else if (
          startLeaveDate.year === date.year &&
          startLeaveDate.month > date.month
        ) {
          alert("تاریخ اتمام مرخصی نمیتواند قبل از تاریخ شروع مرخصی باشد");
          return;
        } else if (startLeaveDate.year > date.year) {
          alert("تاریخ اتمام مرخصی نمیتواند قبل از تاریخ شروع مرخصی باشد");
          return;
        } else {
          setEndLeaveDate(date);
          calculateLeaveDuration(date);
        }
      }
      if (
        date.year === parseInt(year) &&
        date.month === parseInt(month) &&
        date.day < parseInt(day)
      ) {
        alert("تاریخ اتمام مرخصی نمیتواند قبل از تاریخ امروز باشد");
      } else if (date.year === parseInt(year) && date.month < parseInt(month)) {
        alert("تاریخ اتمام مرخصی نمیتواند قبل از تاریخ امروز باشد");
      } else if (date.year < parseInt(year)) {
        alert("تاریخ اتمام مرخصی نمیتواند قبل از تاریخ امروز باشد");
      } else {
        setEndLeaveDate(date);
      }
    }
  };

  const getUserData = () => {
    const authJson = sessionStorage.getItem("auth");
    if (authJson) {
      const { username, token, name } = JSON.parse(authJson);

      axios
        .post(authenticateUserURL, {
          username,
          token,
        })
        .then((res) => {
          if (res.data.status === "Verified") {
            setName(name);
            setId(res.data.id);
            getAvailableUsers(res.data.city);
            axios
              .post(getReplaceRequestsURL, {
                id: res.data.id,
              })
              .then((result) => {
                if (result.data.error) {
                  alert(result.data.message);
                } else {
                  const furArray: any[] = result.data.furlough;

                  const furloughArray: any[] = [];
                  furArray.map((fur, index) => {
                    axios
                      .post(getUserNameURL, {
                        id: fur.mainUserId,
                      })
                      .then((results) => {
                        const obj = {
                          replaceName: results.data,
                          startDate: fur.startDate,
                          endDate: fur.endDate,
                          amount: fur.amount,
                          id: fur.id,
                          replacedUserId: fur.replacedUserId,
                        };

                        furloughArray.push(obj);

                        if (index === furArray.length - 1) {
                          setFurlough(furloughArray);
                        }
                      })
                      .catch((err) => {
                        throw new Error(err);
                      });
                  });
                }
              })
              .catch((err) => {
                throw new Error(err);
              });
          } else {
            history.replace("/");
          }
        })
        .catch((err) => {
          if (err) throw new Error(err);
        });
    } else {
      history.replace("/");
    }
  };

  const getTodayDate = () => {
    setTodayDate(convertDate(new Date()));
  };

  const confirmBtn = () => {
    if (!cause) {
      alert("دلیل مرخصی نمیتواند خالی باشد");
      return;
    } else if (!isDigitalSignChecked) {
      alert("لطفاً فرم را امضا کنید");
      return;
    } else {
      axios
        .post(submitFurloughURL, {
          mainUserId: id,
          replacedUserId: availableUser?.value,
          startDate: `${startLeaveDate?.year}/${startLeaveDate?.month}/${startLeaveDate?.day}`,
          endDate: `${endLeaveDate?.year}/${endLeaveDate?.month}/${endLeaveDate?.day}`,
          amount,
          cause,
        })
        .then((res) => {
          if (res.data.error) {
            alert(res.data.message);
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
  };

  const confirmReplace = (id: number, replaceUserId: number) => {
    axios
      .post(userConfirmReplaceURL, {
        id,
        replaceUserId,
      })
      .then((res) => {
        if (res.data.status === "success") {
          alert("درخواست با موفقیت تایید شد");
          setFurlough((prev: any[]) => {
            if (prev.length === 1) {
              return null;
            } else {
              return prev.splice(
                furlough.indexOf((e: { id: number }) => e.id === id),
                1
              );
            }
          });
        } else {
          alert("خطایی رخ داده لطفاً دوباره تلاشی نمایید");
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const declineReplace = (id: number, replaceUserId: number) => {
    axios
      .post(userDeclineReplaceURL, {
        id,
        replaceUserId,
      })
      .then((res) => {
        if (res.data.status === "success") {
          alert("درخواست با موفقیت لغو شد.");
          setFurlough((prev: any[]) => {
            if (prev.length === 1) {
              return null;
            } else {
              return prev.splice(
                furlough.indexOf((e: { id: number }) => e.id === id),
                1
              );
            }
          });
        } else {
          alert("خطایی رخ داده لطفاً دوباره تلاشی نمایید");
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const getAvailableUsers = (city: undefined) => {
    const dataJson = sessionStorage.getItem("auth");
    if (dataJson) {
      const { username, token } = JSON.parse(dataJson);

      axios
        .post(getAvailableUsersURL, {
          username,
          token,
          city,
        })
        .then((res) => {
          const aua: availableUser[] = [];
          res.data.map((e: { id: string; name: string }) => {
            const au: availableUser = {
              value: e.id,
              label: e.name,
            };
            aua.push(au);
          });
          setAvailableUsers(aua);
          return;
        })
        .catch((err) => {
          if (err) throw new Error(err);
        });
    }
  };

  useEffect(() => {
    getUserData();
    getTodayDate();
    getReplaceRequests();
  }, []);

  return (
    <Grid container>
      <Grid xs={1} sm={2} item />
      <Grid xs={10} sm={8} item container>
        <Paper className={classes.root}>
          <Typography
            gutterBottom
            align="center"
            className={classes.headerText}
          >
            فرم مرخصی روزانه
          </Typography>
          <span className={classes.text}>
            اینجانب <span className={classes.variableText}>{name}</span> در
            تاریخ <span className={classes.variableText}>{todayDate}</span>{" "}
            تقاضای{" "}
            <span className={classes.variableText}>
              <input
                maxLength={3}
                className={classes.input}
                style={{ width: 80 }}
                placeholder={amount.toString()}
                value={amount}
                disabled
              />
            </span>{" "}
            روز مرخصی از تاریخ{" "}
            <span className={classes.variableText}>
              <DatePicker
                value={startLeaveDate}
                onChange={(value) => handleDateChange(value, "start")}
                shouldHighlightWeekends
                locale="fa"
                inputPlaceholder="انتخاب کنید ..."
              />
            </span>{" "}
            لغایت{" "}
            <span className={classes.variableText}>
              <DatePicker
                value={endLeaveDate}
                onChange={(value) => handleDateChange(value, "end")}
                shouldHighlightWeekends
                locale="fa"
                inputPlaceholder="انتخاب کنید ..."
              />
            </span>{" "}
            جهت{" "}
            <span className={classes.variableText}>
              {" "}
              <input
                maxLength={50}
                className={classes.input}
                placeholder="کار شخصی"
                onChange={handleTextChange("cause")}
              />
            </span>{" "}
            را دارم.{" "}
          </span>
          <div />
          <span className={classes.text}>
            جناب آقای/سرکار خانم
            {/* <span style={{ marginLeft: 5 }}></span> */}
            {/* <input className={classes.input} placeholder="انتخاب کنید ..." /> */}
            <Select
              options={availableUsers}
              placeholder="انتخاب کنید ..."
              pageSize={30}
              styles={colourStyles}
              isSearchable={false}
              onChange={(value) => setAvailableUser(value)}
            />
            را به عنوان جایگزین خود انتخاب میکنم .
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 15,
            }}
          >
            <Checkbox
              checked={isDigitalSignChecked}
              onChange={() => setIsDigitalSignChecked((prev) => !prev)}
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <Typography className={classes.text}>امضای الکترونیکی</Typography>
          </div>
          {furlough && (
            <div style={{ marginTop: 25, marginBottom: 25 }}>
              <Typography
                className={classes.headerText}
                style={{ fontSize: 18 }}
              >
                درخواست های جایگزینی شما ({furlough.length})
              </Typography>
            </div>
          )}
          <div>
            {furlough
              ? furlough.map(
                  (
                    {
                      id,
                      replacedUserId,
                      replaceName,
                      amount,
                      startDate,
                      endDate,
                    }: {
                      id: any;
                      replacedUserId: any;
                      replaceName: any;
                      amount: any;
                      startDate: any;
                      endDate: any;
                    },
                    index: any
                  ) => (
                    <div key={index}>
                      <span className={classes.text}>
                        جناب آقای/سرکار خانم{" "}
                        <span className={classes.variableText}>
                          {replaceName}{" "}
                        </span>
                        تقاضای{" "}
                        <span className={classes.variableText}>{amount}</span>{" "}
                        روز مرخصی از تاریخ{" "}
                        <span className={classes.variableText}>
                          {startDate}
                        </span>{" "}
                        الی{" "}
                        <span className={classes.variableText}>{endDate}</span>{" "}
                        داشته و شما را به عنوان جایگزین انتخاب کرده است.{" "}
                      </span>
                      <Button
                        variant="contained"
                        size="large"
                        style={{
                          backgroundColor: "#44bd32",
                          color: "white",
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                        className={classes.confirmBtn}
                        onClick={() => confirmReplace(id, replacedUserId)}
                      >
                        تایید
                      </Button>
                      <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        style={{
                          backgroundColor: "#e84118",
                          color: "white",
                          paddingTop: 5,
                          paddingBottom: 5,
                        }}
                        className={classes.confirmBtn}
                        onClick={() => declineReplace(id, replacedUserId)}
                      >
                        لغو
                      </Button>
                    </div>
                  )
                )
              : null}
          </div>
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 25,
              display: "flex",
            }}
          >
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.confirmBtn}
              onClick={confirmBtn}
            >
              ثبت درخواست
            </Button>
          </div>
        </Paper>
      </Grid>
      <Grid xs={1} sm={2} item />
    </Grid>
  );
};

export default Home;
