export const baseURL = "http://furlough.barasaco.com";

export const signInURL = baseURL + "/api/signIn";
export const getTokenURL = baseURL + "/api/getToken";
export const getAvailableUsersURL = baseURL + "/api/getAvailableUsers";
export const authenticateUserURL = baseURL + "/api/authenticateUser";
export const submitFurloughURL = baseURL + "/api/submitFurlough";
export const getReplaceRequestsURL = baseURL + "/api/getReplaceRequests";
export const getUserNameURL = baseURL + "/api/getUserName";
export const userConfirmReplaceURL = baseURL + "/api/userConfirmReplace";
export const userDeclineReplaceURL = baseURL + "/api/userDeclineReplace";
export const adminConfirmReplaceURL = baseURL + "/api/adminConfirmReplace";
export const adminDeclineReplaceURL = baseURL + "/api/adminDeclineReplace";
export const getRequestsIdURL = baseURL + "/api/getRequestsId";
export const getFurloughURL = baseURL + "/api/getFurlough";
export const createNewUserURL = baseURL + "/api/createNewUser";
